import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { LocalizedMessages } from '../../../shared/models/i18n.model';
import { RegisterWithWellbetDTO } from '../../../shared/models/register-with-wellbet.model';
import { CommonService } from '../../../shared/service/common/common.service';
import { ModalService } from '../../../shared/service/modal/modal.service';
import { PuzzleCaptchaService } from '../../../shared/service/puzzle-captcha/puzzle-captcha.service';
import { UserService } from '../../../shared/service/user/user.service';
import { langxObj } from './register-with-wellbet_langx';

@Component({
  selector: 'app-register-with-wellbet',
  templateUrl: './register-with-wellbet.component.html',
  styleUrl: './register-with-wellbet.component.scss'
})
export class RegisterWithWellbetComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  mediaServer: string = '';
  registerWithWellbetForm: FormGroup;
  registerWithWellbetNextForm: FormGroup;
  passwordControl!: AbstractControl;
  currentLanguage: string = '';
  langx: LocalizedMessages = {};
  shouldShowPasswordText: boolean = false;
  showPasswordStrength: boolean = false;
  isHideNextForm: boolean = true;
  isInvalidCredentials: boolean = false;
  showPasswordError: boolean = false;
  showLoginNameError: boolean = false;
  registrationToken: string = '';
  shouldShowLoginNameInfo: boolean = false;
  isLoginNameValid:boolean = true;
  loginNameErrorMsg:string = '';

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private userService: UserService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private puzzleCaptchaService: PuzzleCaptchaService
  ) {

    this.registerWithWellbetForm = this.formBuilder.group({
      loginName: ['', [
        Validators.required,
      ]],
      password: ['', [
        Validators.required,
      ]]
    });

    this.passwordControl = this.registerWithWellbetForm.get('password')!;
    this.registerWithWellbetNextForm = this.formBuilder.group({
      loginName: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(14),
        this.startsWithLetterValidator()
      ]],
    });

  }

  ngOnInit() {
    this.commonService.mediaServer$.subscribe(mediaServer => this.mediaServer = mediaServer);

    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLanguage => {
        this.currentLanguage = currentLanguage;
        this.langx = langxObj[this.currentLanguage];
      });

    this.userService.showPageBanner();
  }

  registerWithWellbet(registerWithWellbetDTO: RegisterWithWellbetDTO) {
    this.commonService.showLoading();

    this.userService.isLoginNameTaken(registerWithWellbetDTO).subscribe(response => {

      if (response && response.status === 'success') {
        this.registrationToken = response.data.registrationToken;
        
        if (response.data.isLoginNameTaken) {
          this.isHideNextForm = false;
        } else {
          registerWithWellbetDTO.registrationToken = this.registrationToken;
          this.registerWithWellbetLogin(registerWithWellbetDTO);
        }

      } else if (response.errors && response.errors[0].code == 'error.wellbet.login.already-registered') {
        const errorMsg = response.errors.length > 0 ? response.errors[0].message : '';
        this.modalService.showError(errorMsg).subscribe(() => {
          this.router.navigate(['../../login'], { relativeTo: this.route });
        });

      } else if (response.errors && response.errors[0].code == 'error.wellbet.login.invalid-credentials') {
        this.isInvalidCredentials = true;

      } else {
        if(response.errors && response.errors.length){
          this.modalService.showError(response.errors[0].message);
        }
      }

      this.commonService.hideLoading();
    });

  }

  registerWithWellbetLogin(registerWithWellbetDTO: RegisterWithWellbetDTO) {
    this.commonService.showLoading();
    this.userService.registerWellbetAccount(registerWithWellbetDTO).subscribe(response => {

      if (response && response.status === 'error') {
        if(response.errors && response.errors.length){
          if(response.errors[0].code == 'error.register.login-name.already-exists') {
            this.isLoginNameValid = false;
            this.loginNameErrorMsg = response.errors[0].message;
          } else {
            this.modalService.showError(response.errors[0].message);
          }
        }
      } else {
        this.modalService.showSuccess(this.langx['REGISTER_WITH_WELLBET_SUCCESSFUL_TEXT']).subscribe(() => {
          this.router.navigate(['../../login'], { relativeTo: this.route });
        });
      }

      this.commonService.hideLoading();
    });
  }

  isFieldEmpty(field: string): boolean {
    const control = this.registerWithWellbetFormControl[field];
    return control.touched && control.value.length < 1;
  }

  isFieldEmptyNextForm(field: string): boolean {
    const control = this.registerWithWellbetNextFormControl[field];
    return control.touched && control.value.length < 1;
  }

  isFormValid(){
    return this.registerWithWellbetNextForm.valid;
  }

  onRegisterSubmitButtonClick() {
    this.showLoginNameError = false;
    this.showPasswordError = false;
    this.isInvalidCredentials = false;

    if (this.registerWithWellbetForm.valid) {

      this.puzzleCaptchaService.showPuzzleCaptcha()
        .pipe(takeUntil(this.destroy$))
        .subscribe( puzzleCaptchaEvent => {
          if(puzzleCaptchaEvent.type == 'onSuccess') {
            const verificationStub = puzzleCaptchaEvent.verificationStub || '';
            const { ...registerWithWellbetDto } = this.registerWithWellbetForm.value;
            registerWithWellbetDto.verificationStub = verificationStub;
            this.registerWithWellbet(registerWithWellbetDto as RegisterWithWellbetDTO)
          }

          if(puzzleCaptchaEvent.type == 'onClose' ) {
            this.router.navigate(['../login'], { relativeTo: this.route });
          }
        });
      
    } else {
      if (this.registerWithWellbetForm.controls['loginName'].errors &&
        this.registerWithWellbetForm.controls['loginName'].errors['required']) {
        this.showLoginNameError = true;
      }
      if (this.registerWithWellbetForm.controls['password'].errors &&
        this.registerWithWellbetForm.controls['password'].errors['required']) {
        this.showPasswordError = true;
      }
    }
  }

  onRegisterLoginButtonClick() {
    if (this.isFormValid()) {
      const { ...registerWithWellbetDto } = this.registerWithWellbetNextForm.value;
      registerWithWellbetDto.registrationToken = this.registrationToken;

      this.registerWithWellbetLogin(registerWithWellbetDto as RegisterWithWellbetDTO)
    }
  }

  isFieldInvalidFirstForm(field: string): boolean {
    const control = this.registerWithWellbetFormControl[field];
    return control.touched && control.invalid;
  }

  isFieldInvalid(field: string): boolean {
    const control = this.registerWithWellbetNextFormControl[field];
    return control.touched && control.invalid;
  }

  isFieldUntouchedInvalid(field: string): boolean {
    const control = this.registerWithWellbetNextFormControl[field];
    return control.invalid;
  }
  
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get registerWithWellbetFormControl(): { [key: string]: AbstractControl } {
    return this.registerWithWellbetForm.controls;
  }
  
  get registerWithWellbetNextFormControl(): { [key: string]: AbstractControl } {
    return this.registerWithWellbetNextForm.controls;
  }

  onToggleShowPasswordType() {
    this.shouldShowPasswordText = !this.shouldShowPasswordText;
  }

  startsWithLetterValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = /^[A-Za-z][A-Za-z0-9_]*$/.test(control.value);
      return valid ? null : { 'format-invalid': { value: control.value } };
    }
  }
  
  onLoginNameBlur(): void {
    this.shouldShowLoginNameInfo = false;
  }

  onLoginNameKeyUp(): void {
    this.shouldShowLoginNameInfo = true;
    if (!this.isFieldUntouchedInvalid('loginName')) {
      this.shouldShowLoginNameInfo = false;
    }
  }

  onClearInput() {
    this.registerWithWellbetForm.get('loginName')?.setValue('');
    this.registerWithWellbetForm.get('loginName')?.updateValueAndValidity();   
  }

  isLoginNameExists(): boolean {
    return this.isFieldInvalid('loginName') &&
      this.registerWithWellbetNextFormControl['loginName'].hasError('already-exists');
  }
   
  onLoginNameFocus(): void {
    this.shouldShowLoginNameInfo = true;
  }

  onPasswordOrLoginNameOnkeyUp(){
    this.isInvalidCredentials = false;
  }
}
