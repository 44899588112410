<div class="register-with-wellbet-wrapper">

    <div class="register-with-wellbet-form-wrapper">

        @if (isHideNextForm){
            <h1 class="register-with-wellbet-form-title text header text-center">{{ langx['REGISTER_WITH_WELLBET_TITLE'] }}
            </h1>
            <p class="register-with-wellbet-form-description text-small text-center">
                {{ langx['REGISTER_WITH_WELLBET_DESCRIPTION'] }}</p>

            <form [formGroup]="registerWithWellbetForm"
                class="register-with-wellbet-form register-with-wellbet-flex-container text-small">

                <div class="form-group register-with-welllbet-flex-item">
                    <input type="text" class="form-control register-with-wellbet-field-item"
                        placeholder="{{ langx['REGISTER_WITH_WELLBET_LOGIN_NAME_PLACEHOLDER'] }}" formControlName="loginName"
                        [class.invalid]="isFieldInvalidFirstForm('loginName')" (keyup)="onPasswordOrLoginNameOnkeyUp()" 
                        #loginNameInputField/>
                    
                    <app-clear-input-icon [targetInputField]="loginNameInputField" (clearInput)="onClearInput()"  [rightPositionClass]= "'right-large'"/>

                    @if (isFieldEmpty('loginName')) {
                        <div class="register-with-wellbet-field-error text-small"><span
                                class="material-symbols-outlined">error</span>
                            {{ langx['REGISTER_WITH_WELLBET_LOGIN_NAME_EMPTY'] }}</div>
                    }
                </div>

                <div class="form-group register-with-wellbet-flex-item">
                    <input [type]="shouldShowPasswordText? 'text' : 'password'"
                        class="form-control register-with-welllbet-field-item"
                        placeholder="{{ langx['REGISTER_WITH_WELLBET_PASSWORD_PLACEHOLDER'] }}" formControlName="password"
                        [class.invalid]="isFieldInvalidFirstForm('password')" (keyup)="onPasswordOrLoginNameOnkeyUp()"/>
                        
                    <span class="show-password-icon-wrapper">

                        <span class="material-symbols-outlined" (click)="onToggleShowPasswordType()">
                            @if(shouldShowPasswordText){
                                <img class="logo icon-password-visibility {{ passwordControl.value?'':'opaque' }}" src="{{ mediaServer }}/zeta/common/icon_password-visible.png" alt="">
                            } @else {
                                <img class="logo icon-password-visibility {{ passwordControl.value?'':'opaque' }}" src="{{ mediaServer }}/zeta/common/icon_password-hidden.png" alt="">
                            }
                        </span>

                    </span>
                    @if (isFieldEmpty('password')) {
                        <div class="register-with-wellbet-field-error text-small"><span
                                class="material-symbols-outlined">error</span>
                            {{ langx['REGISTER_WITH_WELLBET_PASSWORD_EMPTY'] }}</div>
                    }
                    @else if (isInvalidCredentials) {
                        <div class="register-with-wellbet-field-error text-small"><span
                                class="material-symbols-outlined">error</span>
                            {{ langx['REGISTER_WITH_WELLBET_CREDENTIALS_INVALID'] }}</div>
                    }
                </div>

                <button type="submit" class="btn btn-primary btn-block register-with-wellbet-button-margin" 
                    [disabled]="!this.registerWithWellbetForm.valid" (click)="onRegisterSubmitButtonClick()">
                    {{ langx['REGISTER_WITH_WELLBET_SUBMIT_TEXT'] }}
                </button>

            </form>
        }
        @else {
            <h1 class="register-with-wellbet-form-title text header text-center">{{ langx['REGISTER_WITH_WELLBET_TITLE2'] }}
            </h1>
            <p class="register-with-wellbet-form-description text-small text-center">
                {{ langx['REGISTER_WITH_WELLBET_DESCRIPTION2'] }}</p>
            <form [formGroup]="registerWithWellbetNextForm"
                class="register-with-wellbet-form register-with-wellbet-flex-container text-small">

                <div class="form-group register-with-wellbet-flex-item">
                    <input type="text" class="form-control register-with-wellbet-field-item"
                        placeholder="{{ langx['REGISTER_WITH_WELLBET_LOGIN_NAME_PLACEHOLDER'] }}" formControlName="loginName"
                        (focus)="onLoginNameFocus()"
                        (blur)="onLoginNameBlur()"
                        (keyup)="onLoginNameKeyUp()"
                        [class.invalid]="isFieldInvalid('loginName')"
                        #loginNameField
                        />

                    <app-clear-input-icon [targetInputField]="loginNameField"  [rightPositionClass]= "'right-large'"/>

                    @if(shouldShowLoginNameInfo){
                        <div class="register-with-wellbet-field-info text-small"><span class="material-symbols-outlined">info</span> {{langx['REGISTER_WITH_WELLBET_LOGINNAME_FORMAT_INVALID']}}</div>
                    } @else if (isFieldEmptyNextForm('loginName')) {
                        <div class="register-with-wellbet-field-error text-small"><span class="material-symbols-outlined">error</span> {{langx['REGISTER_WITH_WELLBET_LOGIN_NAME_EMPTY']}}</div>
                    } @else if (isFieldInvalid('loginName')) {
                        <div class="register-with-wellbet-field-error text-small"><span class="material-symbols-outlined">error</span> {{langx['REGISTER_WITH_WELLBET_LOGINNAME_FORMAT_INVALID']}}</div>
                    }

                </div>

                <div class="form-group register-with-wellbet-flex-item">
                    &nbsp;
                </div>

                <button type="submit" class="btn btn-primary btn-block register-with-wellbet-button-bottom-margin" 
                    [disabled]="!this.registerWithWellbetNextForm.valid" 
                    (click)="onRegisterLoginButtonClick()">
                    {{ langx['REGISTER_WITH_WELLBET_LOGIN_TEXT'] }}
                </button>

            </form>
        }

        <div class="register-redirection text-center">
            <small class="text-very-small">{{ langx['REGISTER_WITH_WELLBET_LOGIN_EXIST_TEXT'] }}</small>
            <a [routerLink]="['../../login']" class="link text-very-small">
                {{ langx['REGISTER_WITH_WELLBET_LOGIN_TEXT'] }}
            </a>
        </div>

    </div>

    <app-cs-chat-icon></app-cs-chat-icon>
</div>
